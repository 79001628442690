import { createSlice } from '@reduxjs/toolkit'
import { customersInitialState as initialState } from '../initialState/customersState'
import { apiCallBegan } from '../api'
import { globalViewText } from "../../utilities/config"
import { CookieUtils } from '../../utilities/cookies'
import { decodeToken } from '../../utilities/decodeToken'
import logoWithText from '../../assets/pixm-blue-on-white-logo-02@3x.png'
const slice = createSlice({
  name: 'customers',
  initialState: initialState,
  reducers: {

    setCustomersFilters: (customers, action) => {
      customers.filters = action.payload
    },

    customersRequested: (customers, action) => {
      customers.userList = initialState.userList
      customers.needRefreshToken = initialState.needRefreshToken
      customers.loading = true
    },
    beforeGetCustomerList: (customers, action) => {
      customers.loading = true
    },

    activeUsersRequested: (customers, action) => {
      customers.activeUserCount = initialState.activeUserCount
      customers.loading = true
    },

    extensionUsersRequested: (customers, action) => {
      customers.userList = initialState.userList
      customers.needRefreshToken = initialState.needRefreshToken
      customers.extensionUsersLoading = true
    },

    registeredUsersRequested: (customers, action) => {
      customers.registeredUsersLoading = true
      customers.registeredUsersErrorMsg = ''
    },

    newUsersRequested: (customers, action) => {
      customers.newUsersLoading = true
    },

    detectedUrlBreakdownsRequested: (customers, action) => {
      customers.detectedUrlBreakdownsLoading = true
    },

    verifiedUrlBreakdownsRequested: (customers, action) => {
      customers.verifiedUrlBreakdownsLoading = true
    },

    urlUserBreakdownsRequested: (customers, action) => {
      customers.urlUserBreakdownsLoading = true
    },

    breakDownsRequested: (customers, action) => {
      customers.breakdownsLoading = true
    },

    customersRequestedOnSearch: (customers, action) => {
      customers.userList = initialState.userList
      customers.needRefreshToken = initialState.needRefreshToken
      customers.loading = false
    },

    callGetUsersOnce: (customers, action) => {
      customers.isGetUserOnce = false
    },

    updateCustomer: (customers, action) => {
      customers.selected = action.payload
      // customers.licenseUsers = initialState.licenseUsers
      // customers.totalSize = initialState.totalSize
      // customers.filters.offset = initialState.filters.offset
      // customers.filters.pageSize = initialState.filters.pageSize
      // customers.usersCount = initialState.usersCount
      // customers.loading = false
    },

    // customersReceived: (customers, action) => {
    //   let customersList = action.payload.response.customers;
    //   let tenantList = action.payload.response.tenants;
    //   // console.log("customersReceived", action.payload);
    //   if (customersList) {
    //     customers.list = customersList.sort((a, b) => (a.organizationName.toLowerCase() > b.organizationName.toLowerCase()) ? 1 : ((b.organizationName.toLowerCase() > a.organizationName.toLowerCase()) ? -1 : 0));//action.payload.customers
    //     customers.tenantList = tenantList
    //     customers.selected = customers.selected ?? customersList[0]
    //   } else {
    //     customers.list = []
    //     customers.tenantList = []
    //     customers.selected = {}
    //   }
    //   customers.createCustomerFlag = false
    //   customers.loading = false
    // },

    customersReceived: (customers, action) => {  // renamed from customersReceivedPixmAdmin to customersReceived
      // console.log("customersReceivedPixmAdmin", action.payload);
      //Concatanate Tenant-name and customer name
      let customersList = action.payload.response.customers;
      let tenantList = action.payload.response.tenants;
      let role = action.payload.response.role;
      // let hideGlobalView = tenantList.length > 0 ? tenantList[0].hideGlobalView : false;
      let mapTenantGroups = {};
      // let isPixmAdmin = (tenantList instanceof Array && tenantList.length > 1);
      // let isMSPAdmin = (tenantList instanceof Array && tenantList.length === 1);

      if(customersList) {
        if (role === 'PixmAdmin' || role === 'TenantAdmin') {
          customersList.forEach(customer => {
            let tenant = tenantList.filter(item => item.tenantId == customer.tenantId);
            let isGlobalViewDisabled = (typeof tenant[0].hideGlobalView === 'boolean' && tenant[0].hideGlobalView) || (customer.organizationName === tenant[0].tenantName);
            if(!isGlobalViewDisabled) {
              if(!isTenantExist(mapTenantGroups, customer.tenantId)){
                mapTenantGroups[customer.tenantId] = {displayName: tenant[0].tenantName, grpCount: 1, grpIds: [customer.groupId], defaultCustomers: [customer.id]};
              }
              else {
                if(!mapTenantGroups[customer.tenantId].grpIds.includes(customer.groupId)){
                  mapTenantGroups[customer.tenantId].grpCount = mapTenantGroups[customer.tenantId].grpCount + 1;
                  mapTenantGroups[customer.tenantId].grpIds.push(customer.groupId);
                  mapTenantGroups[customer.tenantId].defaultCustomers.push(customer.id);
                }
              }
            }
            customer.displayName = typeof tenant !== 'undefined' && tenant.length > 0 && customer.organizationName != tenant[0].tenantName && role === 'PixmAdmin'? tenant[0].tenantName + " > " + customer.organizationName : customer.organizationName;
            customer.key = customer.id;
          });
          let customer = customersList.filter(customer => customer.id === `${process.env.REACT_APP_FREE_CUSTOMER_ID}`)
          customers.selected = customers.selected ?? customer[0]
        }
        
        Object.entries(mapTenantGroups).map(([key, value]) => {
          if (value.grpCount <= 1 ) {
            customersList.push({
            "id": key,
            "isActive": true,
            "isProvisioned": true,
            "created": null,
            "organizationName":globalViewText, /*globalViewText,*/
            "tenantId": key,
            "firstName": "",
            "lastName": "",
            "email": "",
            "sortKey" : key + value.grpIds[0],
            "phone": null,
            "groupId": value.grpIds[0], // This is the dbHash
            "multiGroup": false,
            "displayName" : role === "TenantAdmin" ? globalViewText : (value.displayName + ' > ' + globalViewText),
            "key" : key + value.grpIds[0],
            "defaultCustomerId" : value.defaultCustomers[0], // This is the default customerId for database connection on backend
          }) 
        } else {
          value.grpIds.map((grpId, index) => {
            customersList.push({
              "id": key,
              "isActive": true,
              "isProvisioned": true,
              "created": null,
              "organizationName": globalViewText, /*globalViewText,*/
              "tenantId": key,
              "firstName": "",
              "lastName": "",
              "email": "",
              "phone": null,
              "sortKey" : key + grpId,
              "groupId": grpId, // This is the dbHash
              "multiGroup": true,
              "displayName" : role === "TenantAdmin" ? `${globalViewText+" "}${index + 1}` : (value.displayName + ' > ' + globalViewText+ " " + (index + 1)),
              "key" : key + grpId,
              "defaultCustomerId" : value.defaultCustomers[index],
            });
          });

        }});

        customers.list = addSortKeyWord(customersList);
        customers.list = customers.list.sort((a, b) => (a.sortKeyword.toLowerCase() > b.sortKeyword.toLowerCase()) ? 1 : ((b.sortKeyword.toLowerCase() > a.sortKeyword.toLowerCase()) ? -1 : 0))
        customers.tenantList = tenantList
        // if(role === "TenantAdmin" && !hideGlobalView){
        //   customers.list.splice(0,0, addGlobalViewAsCustomer(customers.tenantList[0]));
        //   customers.selected = customers.selected ?? customers.list[0]
        // }
        // else{
        //   customers.selected = customers.selected ?? customers.list[0]
        // }
        // if(!hideGlobalView && role !== "CustomerAdmin"){
        //     customers.list = addGlobalView(customers.list, role, tenantList);
        //     let customersWithSortKeyword = addSortKeyWord(customers.list);
        //     let sortedCustomer = sortCustomers(customersWithSortKeyword);
        //     customers.list = checkForMultiGroup(sortedCustomer);
        // }


        customers.selected = customers.selected ?? customers.list[0]

      } else {
        customers.list = []
        customers.tenantList = []
        customers.selected = {}
      }
      customers.createCustomerFlag = false
      customers.loading = false
    },

    // updateCustomerList: (customers, action) => {
    //   if (action.payload.customers) {
    //     customers.list = action.payload.customers
    //     customers.tenantList = action.payload.tenants
    //   } else {
    //     customers.list = []
    //     customers.tenantList = []
    //   }
    //   customers.createCustomerFlag = false
    //   customers.loading = false
    // },

    // customersRequestFailed: (customers, action) => {
    //   toast.error("could not fetch customers")
    // },

    brandsReceived: (customers, action) => {
      let brandsList = [{ name: 'Brand: All', value: 'allbrands', id: 'allbrands' }]
      action.payload.response.map((brand) => {
        brandsList.push({ name: brand.name, value: brand.name, id: brand.name })
      })
      customers.brands = brandsList
      customers.loading = false
    },

    brandsFailed: (customers, action) => {
      customers.loading = false
      customers.userMsg = null
      customers.statusCode = null
    },

    extensionUsersReceived: (customers, action) => {
      let usersList = [{ name: 'User: All', value: 'allusers', id: 'allusers' }]
      action.payload.response.records.map((user) => {
        let displayName;
        if (user.oSUserId) {
          displayName = user.oSUserId
        } else if (user.oSComputerName) {
          displayName = user.oSComputerName
        } else if (user.oSUUID) {
          displayName = user.oSUUID
        } else if (user.email) {
          displayName = user.email
        } else {
          displayName = 'no name'
        }
        usersList.push({ name: displayName, value: user.id, id: user.id, isActive: user.isActive, created: user.created, customerId: user.customerId })
      })
      customers.loading = false
      customers.users = usersList
    },

    searchedExtensionUsersReceived: (customers, action) => {
      let usersList = [{ name: 'User: All', value: 'allusers', id: 'allusers' }]

      action.payload.response.records.map((user) => {
        let displayName;
        if (user.oSUserId) {
          displayName = user.oSUserId
        } else if (user.oSComputerName) {
          displayName = user.oSComputerName
        } else if (user.oSUUID) {
          displayName = user.oSUUID
        } else if (user.email) {
          displayName = user.email
        } else {
          displayName = 'no name'
        }

        usersList.push({ name: displayName, value: user.id, id: user.id, isActive: user.isActive, created: user.created, customerId: user.customerId })
      })
      customers.loading = false
      customers.usersSearchText = action.payload.response.term
      customers.searchedUsers = usersList
    },

    resetSearchedUsers: (customers, action) => {
      customers.searchedUsers = [{ name: 'User: All', value: 'allusers', id: 'allusers' }]
    },

    extensionUsersFailed: (customers, action) => {
      customers.loading = false
      customers.userMsg = null
      customers.statusCode = null
    },

    userListRequested: (customers, action) => {
      customers.loadingUserList = true;
      customers.licenseUserListApiFailed = false
    },

    usersReceived: (customers, action) => {
      let usersList = []
      const { records, filters, totalRecords } = action.payload.response
      
      records.map((user) => {
        let displayName;
        if (user.oSUserId) {
          displayName = user.oSUserId
        } else if (user.oSComputerName) {
          displayName = user.oSComputerName
        } else if (user.oSUUID) {
          displayName = user.oSUUID
        } else if (user.email) {
          displayName = user.email
        } else {
          displayName = 'no name'
        }
        usersList.push({ name: displayName, oSUserId: user.oSUserId, osuuid: user.oSUUID, osComputerName:user.osComputerName, value: user.id, id: user.id, isActive: user.isActive, created: user.created, customerId: user.customerId })

      })
      customers.loadingUserList = false
      customers.licenseUsers = usersList
      customers.totalSize = totalRecords
      customers.filters.offset = filters.offset
      customers.filters.pageSize = filters.pageSize
      customers.loadingUserList = false;
      customers.licenseUserListApiFailed = false;

    },

    deployedLicensesGrowthRequested: (customers, action) => {
      customers.userList = initialState.userList
      customers.needRefreshToken = initialState.needRefreshToken
      customers[`${action.reducerData.getDataByPeriod}ChartLoading`] = true;
    },

    deployedLicensesGrowthReceived: (customers, action) => {
      customers.loading = false
      customers.deployedLicensesGrowth = action.payload.response
      customers[`${action.reducerData.getDataByPeriod}ChartLoading`] = false;
    },

    activeUserGrowthReceived: (customers, action) => {
      customers.loading = false
      customers.activeUserGrowth = action.payload.response
    },

    newUsersReceived: (customers, action) => {
      const { values } = action.payload.response
      customers.newCustomersValues = values
      customers.newUsersLoading = false
      customers.newUsersErrorMsg = ''
    },

    registeredUsersReceived: (customers, action) => {
      const { values } = action.payload.response
      customers.registerUserValue = values
      customers.registeredUsersLoading = false
    },

    usersReceivedBySearch: (customers, action) => {
      let usersList = []
      let user = action.payload.response
      usersList.push({ name: user.email, oSUserId: user.osUserId, oSComputerName: user.osComputerName, oSUUID: user.osUUID, id: user.id, isActive: user.isActive, created: user.created, customerId: user.customerId })

      customers.loading = false
      customers.licenseUsers = usersList
      customers.totalSize = usersList.length
      customers.loadingUserList = false;
    },

    usersFailed: (customers, action) => {
      customers.loading = false
      customers.licenseUserListApiFailed = true;
      customers.statusCode = null
      customers.loadingUserList = false;
      customers.licenseUsers = [];
    },

    deployedLicensesGrowthFailed: (customers, action) => {
      customers.loading = false
      customers.userMsg = null
      customers.statusCode = null
      customers.deployedLicensesGrowth = []
      customers[`${action.reducerData.getDataByPeriod}ChartLoading`] = false;

    },

    activeUserGrowthFailed:(customers, action) => {
      customers.loading = false
      customers.userMsg = null
      customers.statusCode = null
      customers.activeUserGrowth = []
    },

    registeredUsersFailed: (customers, action) => {
      customers.registeredUsersLoading = false
      customers.registeredUsersErrorMsg = 'Something went wrong, try refreshing chart'
    },

    newUsersFailed: (customers, action) => {
      customers.newUsersLoading = false
      customers.newUsersErrorMsg = 'Something went wrong, try refreshing chart'
    },

    activeUsersCountReceived: (customers, action) => {
      customers.activeUserCount = action.payload.response.totalActiveUsers
      customers.loading = false
    },

    extensionUsersCountReceived: (customers, action) => {
      customers.usersCount = action.payload.response.totalUsers
      customers.extensionUsersLoading = false
      customers.showWelcomePopup = action.payload.response.totalUsers < 1
      customers.extensionUsersErrorMsg = ''
    },

    breakDownsReceived: (customers, action) => {
      const { browser, os, device } = action.payload.response
      customers.browser = browser
      customers.os = os
      customers.device = device
      customers.breakdownsLoading = false
      customers.breakDownsErrorMsg = ''
    },

    detectedUrlBreakdownsReceived: (customers, action) => {
      const { xaxis, legend, values, total } = action.payload.response
      customers.detectedUrlXaxis = xaxis
      customers.detectedUrlLegend = legend
      customers.detectedUrlValues = values
      customers.detectedUrlTotal = total
      customers.detectedUrlBreakdownsLoading = false
      customers.detectedUrlBreakdownsErrorMsg = ''
    },

    verifiedUrlBreakdownsReceived: (customers, action) => {
      const { legend, values } = action.payload.response
      customers.verifiedEventsLegends = legend
      customers.verifiedEventsValues = values
      customers.verifiedUrlBreakdownsLoading = false
      customers.verifiedUrlBreakdownsErrorMsg = ''
    },

    urlUserBreakdownsReceived: (customers, action) => {
      const { legend, values } = action.payload.response
      customers.urlUsersLegends = legend
      customers.urlUsersValues = values
      customers.urlUserBreakdownsLoading = false
      customers.urlUserBreakdownsErrorMsg = ''
    },

    activeUsersCountFailed: (customers, action) => {
      customers.loading = false
      customers.userMsg = null
      customers.statusCode = null
      customers.activeUserCount = 0
      customers.extensionUsersLoading = false
      customers.extensionUsersErrorMsg = 'Something went wrong, try refreshing chart'
    },

    extensionUsersCountFailed: (customers, action) => {
      customers.loading = false
      customers.userMsg = null
      customers.statusCode = null
      customers.usersCount = 0
      customers.extensionUsersLoading = false
      customers.extensionUsersErrorMsg = 'Something went wrong, try refreshing chart'
    },

    breakDownsFailed: (customers, action) => {
      customers.browser = null
      customers.os = null
      customers.device = null
      customers.breakdownsLoading = false
      customers.breakDownsErrorMsg = 'Something went wrong, try refreshing chart'
    },

    detectedUrlBreakdownsFailed: (customers, action) => {
      customers.detectedUrlBreakdownsLoading = false
      customers.detectedUrlBreakdownsErrorMsg = 'Something went wrong, try refreshing chart'
    },

    verifiedUrlBreakdownsFailed: (customers, action) => {
      customers.verifiedUrlBreakdownsLoading = false
      customers.verifiedUrlBreakdownsErrorMsg = 'Something went wrong, try refreshing chart'
    },

    urlUserBreakdownsFailed: (customers, action) => {
      customers.urlUserBreakdownsLoading = false
      customers.urlUserBreakdownsErrorMsg = 'Something went wrong, try refreshing chart'
    },

    extensionUserByIdReceived: (customers, action) => {
      customers.loading = false
      customers.userDetails = action.payload.response
    },

    extensionUserByIdFailed: (customers, action) => {
      customers.loading = false
      customers.userMsg = 'failed to search extension user by id.'
      customers.statusCode = 500
    },

    resetUser: (customers, action) => {
      customers.loading = false
      customers.userDetails = null
    },

    nextUsersReceived: (customers, action) => {
      const { records, filters, totalRecords } = action.payload.response
      let usersList = []

      records.map((user) => {
        let displayName;
        if (user.oSUserId) {
          displayName = user.oSUserId
        } else if (user.oSComputerName) {
          displayName = user.oSComputerName
        } else if (user.oSUUID) {
          displayName = user.oSUUID
        } else if (user.email) {
          displayName = user.email
        } else {
          displayName = 'no name'
        }
        usersList.push({ name: displayName, oSUserId: user.oSUserId, osuuid: user.oSUUID, osComputerName:user.osComputerName, value: user.id, id: user.id, isActive: user.isActive, created: user.created, customerId: user.customerId })
      })

      customers.loading = false
      customers.licenseUsers = [...customers.licenseUsers, ...usersList]
      customers.totalSize = totalRecords
      customers.filters.offset = filters.offset
      customers.filters.pageSize = filters.pageSize
    },

    pervUsersReceived: (customers, action) => {
      customers.loading = false
      customers.filters.offset = customers.filters.offset - customers.filters.pageSize
    },

    usersRequestFailed: (customers, action) => {
      customers.loading = false
      customers.userMsg = 'failed to load records'
      customers.statusCode = 500
    },

    cacheHit: (customers, action) => {
      customers.loading = false
      customers.filters.offset = action.payload.data.offset
    },

    createCustomerSuccess: (customers) => {
      customers.loading = false
      customers.userMsg = 'Customer has been added.'
      customers.statusCode = 200
      customers.createCustomerFlag = true
    },

    createCustomerFailed: (customers, action) => {
      let errorDesc = action.payload.errors[0].desc || "";
      customers.loading = false
      customers.userMsg = 'Could not add customer :' +  errorDesc; 
      customers.statusCode = 500
    },

    onErrorGetCustomerList: (customers, action) => {
      customers.loading = false
      customers.userMsg = 'Could not fetch customers'
    },

    deleteCustomerSuccess: (customers) => {
      customers.loading = false
      customers.createCustomerFlag = true
      customers.userMsg = 'Customer has been deleted.'
      customers.statusCode = 200
      if (customers.customerDetails) {
        customers.customerDetails.isActive = false;
      }
    },

    deleteCustomerFailed: (customers) => {
      customers.loading = false
      customers.userMsg = 'could not delete customer!!'
      customers.statusCode = 500
    },

    updateCustomerSuccess: (customers) => {
      customers.loading = false
      customers.createCustomerFlag = true
      customers.userMsg = 'Customer has been updated!!'
      customers.statusCode = 200
    },

    updateCustomerFailed: (customers, action) => {
      let errorDesc = action.payload.errors[0].desc || "";
      customers.loading = false
      customers.userMsg = 'Could not Update the customer : '+errorDesc;
      customers.statusCode = 500
    },

    usersListSuccess: (customers, action) => {
      customers.loading = false
      customers.userList = action.payload.response
      customers.userListFlag = false
    },

    usersListFailed: (customers) => {
      customers.loading = false
      customers.userMsg = 'could not load users list.'
      customers.statusCode = 500
    },

    removeAdminSuccess: (customers, action) => {
      customers.loading = false
      let msg = action.payload.response.message

      let token = CookieUtils.get("token");
      let decodedToken = decodeToken(token);

      if (msg.includes(decodedToken.user_id)) {
        let BASE_URL = process.env.REACT_APP_BASE_WHITELIST_URL.replace("<DOMAIN>", CookieUtils.getCustomerDomain());
        window.location.replace(`${BASE_URL}/product/login.html?u=${process.env.REACT_APP_API_BASE_URL}&b=${process.env.REACT_APP_BASE_WHITELIST_URL}&s=${process.env.REACT_APP_REGISTER_NEW_URL}`);

        //window.location.replace(`${process.env.REACT_APP_LOGIN_WINDOW_URL}`)
      }

      customers.userListFlag = true
      customers.userMsg = 'Admin has been removed.'
      customers.statusCode = 200
    },

    removeAdminFailed: (customers) => {
      customers.loading = false
      customers.userMsg = 'could not remove admin.'
      customers.statusCode = 500
    },

    inviteAdminSuccess: (customers) => {
      customers.loading = false
      customers.userListFlag = true
      customers.userMsg = 'Admin user has been invited.'
      customers.statusCode = 200
    },

    inviteAdminFailed: (customers) => {
      customers.loading = false
      customers.userMsg = 'Could not invite admin user.'
      customers.statusCode = 500
    },

    addCustomerSuccess: (customers, action) => {
      //customers.loading = true
      let customerData = action.payload.response.data[0];
      customers.creds = action.payload.data;
      customers.userMsg = customerData.isMSP && customerData.isMSP === true
                          ? `MSP Customer has been created Successfully. \n Username : ${customerData.username} \n Password : ${customerData.password}`
                          : `Direct Customer has been created Successfully. \n Username : ${customerData.username} \n Password : ${customerData.password}`
      customers.statusCode = 200
    },

    addCustomerFailed: (customers, action) => {
      let errorDesc = "Internal Server Error. Please Contact Administrator";
      if (action.payload.errors && action.payload.errors.length > 0) {
        errorDesc = "";
        action.payload.errors.map((error, index) => {
          errorDesc += `- ${error.desc}\n`;
        })
      }
      customers.loading = false
      customers.userMsg = errorDesc
      customers.statusCode = 500
    },

    updateTenantInfoSuccess: (customers) => {
      customers.loading = false
      customers.userMsg = 'Information has been updated.'
      customers.statusCode = 200
    },

    updateTenantInfoIsCustomizedSuccess: (customers, action) => {
      customers.loading = false
      customers.isCustomized = action.payload.response.respone.isCustomized // API responding respone obj
      customers.userMsg = 'Whitelabel flag has been updated.'
      customers.statusCode = 200
    },

    updateTenantInfoWhitelabelSuccess: (customers, action) => {
      customers.logo = action.payload.response.brandLogo ? action.payload.response.brandLogo : customers.logo
      customers.isCustomized = false
      customers.userMsg = 'Whitelabel information has been updated.'
      customers.statusCode = 200
    },

    updateTenantInfoIsCustomizedFailure: (customers, action) => {
      customers.loading = false
      customers.userMsg = 'Could not change whitelabel flag'
      customers.statusCode = 500
    },

    updateTenantInfoWhitelabelFailure: (customers, action) => {
      customers.loading = false
      customers.userMsg = 'Could not change whitelabel information'
      customers.statusCode = 500
    },

    updateTenantInfoFailed: (customers) => {
      customers.loading = false
      customers.userMsg = 'could not update tenant info.'
      customers.statusCode = 500
    },

    cancelAccountSuccess: (customers) => {
      customers.loading = false
      customers.userMsg = 'Account has been cancelled.'
      customers.statusCode = 200
    },

    cancelAccountFailed: (customers, action) => {
      customers.loading = false
      customers.userMsg = action.payload
      customers.statusCode = 500
    },

    getTenantDetailsSuccess: (customers, action) => {
      customers.loading = false
      customers.tenantDetails = action.payload.response
    },

    getTenantInfoWhitelabelSuccess: (customers, action) => {
      customers.loading = false
      customers.tenantWhitelabelInfo = action.payload.response
    },

    getTenantDetailsFailed: (customers) => {
      customers.loading = false
      customers.userMsg = 'could not get tenant details!'
      customers.statusCode = 500
    },

    getCustomerDetailsSuccess: (customers, action) => {
      customers.loading = false
      customers.customerDetails = action.payload.response
    },

    getCustomerDetailsFailed: (customers) => {
      customers.loading = false
      customers.userMsg = 'could not get customer details!'
      customers.statusCode = 500
    },

    updateUserInfoSuccess: (customers, action) => {
      customers.loading = false
      customers.userMsg = 'User information has been updated'
      customers.statusCode = 200
      customers.needRefreshToken = true
    },

    updateUserInfoFailed: (customers, action) => {
      customers.loading = false
      customers.userMsg = 'could not update user details'
      customers.statusCode = 500
    },

    resetModalSuccess: (customers) => {
      customers.loading = false
      customers.statusCode = null
    },

    setCustomError: (customers, action) => {
      customers.loading = false
      customers.userMsg = action.payload
      customers.statusCode = 500
    },

    setCustomInfo: (customers, action) => {
      customers.loading = false
      customers.userMsg = action.payload
      customers.statusCode = 203
    },

    customerAlertConfigRequested: (customers, action) => {
      customers.alertConfigLoading = true
      customers.alertConfig = {}
      customers.isGFormScanningEnabled = false;
    },

    getCustomerAlertConfigSuccess: (customers, action) => {
      customers.alertConfigLoading = false
      customers.alertConfig = action.payload.response
      customers.isGFormScanningEnabled = action.payload.response.isGFormScanningEnabled || false;
    },

    getCustomerAlertConfigFailed: (customers) => {
      customers.alertConfigLoading = false
      customers.userMsg = 'Could not get customer alert config!'
      customers.statusCode = 500
    },
    updateCustomerAlertConfigRequested :(customers, action) => {
      customers.alertConfigLoading = true
    },
    updateCustomerAlertConfigSuccess: (customers, action) => {
      customers.alertConfigLoading = false
      customers.alertConfig = action.payload.response
      customers.isGFormScanningEnabled = action.payload.response.isGFormScanningEnabled || false;
      customers.userMsg = 'Configuration updated successfully'
      customers.statusCode = 200;
    },

    updateCustomerAlertConfigFailed: (customers) => {
      customers.alertConfigLoading = false
      customers.userMsg = 'could not update customer alert config!'
      customers.statusCode = 500
    },

    whitelistDomainRequested: (customers, action) => {
      customers.whitelistDomainFlag = true
    },

    whitelistDomainSuccess: (customers, action) => {
      customers.loading = false;
      customers.whitelistDomainFlag = false;
      let listWhitelistedDomains = action.payload.response;
      customers.whitelistDomains = listWhitelistedDomains.sort((a, b) => (a.authorizedDomain.toLowerCase() > b.authorizedDomain.toLowerCase()) ? 1 : ((b.authorizedDomain.toLowerCase() > a.authorizedDomain.toLowerCase()) ? -1 : 0));
    },

    whitelistDomainFailed: (customers) => {
      customers.loading = false;
      customers.whitelistDomainFlag = false;
      customers.userMsg = 'could not fetch domain list!';
      customers.statusCode = 500;
    },

    removeWhitelistDomainRequested: (customers, action) => {
      customers.removeWhitelistDomainFlag = true
    },

    removeWhitelistDomainSuccess: (customers) => {
      customers.loading = false
      customers.removeWhitelistDomainFlag = false
      customers.userMsg = 'Domain deleted successfully'
      customers.statusCode = 200
    },

    removeWhitelistDomainFailed: (customers) => {
      customers.loading = false;
      customers.removeWhitelistDomainFlag = false;
      customers.userMsg = 'Failed to delete domain';
      customers.statusCode = 500;
    },

    addWhitelistDomainRequested: (customers, action) => {
      customers.addWhitelistDomainFlag = true;
    },

    addWhitelistDomainSuccess: (customers) => {
      customers.loading = false
      customers.addWhitelistDomainFlag = false
      customers.userMsg = 'Domain added successfully'
      customers.statusCode = 200
    },

    addWhitelistDomainFailed: (customers) => {
      customers.loading = false;
      customers.addWhitelistDomainFlag = false;
      customers.userMsg = 'Failed to add domain';
      customers.statusCode = 500;
    },

    blacklistUrlsRequested: (customers, action) => {
      customers.loading = true;
    },

    blacklistUrlsSuccess: (customers, action) => {
      customers.loading = false;
      customers.blacklistUrlFlag = false;
      let listBlacklistedDomains = action.payload.response;
      customers.blacklistUrls = listBlacklistedDomains.sort((a, b) => (a.url.toLowerCase() > b.url.toLowerCase()) ? 1 : ((b.url.toLowerCase() > a.url.toLowerCase()) ? -1 : 0));
    },

    blacklistUrlsFailed: (customers) => {
      customers.loading = false
      customers.userMsg = 'could not load urls!'
      customers.statusCode = 500
    },

    removeBlacklistUrlsRequested: (customers, action) => {
      customers.loading = true;
    },

    removeBlacklistUrlsSuccess: (customers) => {
      customers.loading = false
      customers.blacklistUrlFlag = true
      customers.userMsg = 'Url deleted successfully'
      customers.statusCode = 200
    },

    removeBlacklistUrlsFailed: (customers) => {
      customers.loading = false
      customers.userMsg = 'Failed to delete URL'
      customers.statusCode = 500
    },

    addBlacklistUrlRequested: (customers, action) => {
      customers.loading = true;
    },

    addBlacklistUrlSuccess: (customers, action) => {
      if (action.payload.response.error && action.payload.response.error.length > 0){
        let error = action.payload.response.error
        customers.loading = false
        customers.userMsg = error[0].desc
        customers.statusCode = error[0].code
      } else {
        customers.loading = false
        customers.blacklistUrlFlag = true
        customers.userMsg = 'Url has been added'
        customers.statusCode = 200
      }
    },

    addBlacklistUrlFailed: (customers) => {
      customers.loading = false
      customers.userMsg = 'Failed to add URL'
      customers.statusCode = 500
    },

    fetchApiKeyDetailsByCustomerIdSuccess: (customers, action) => {
      customers.loading = false
      customers.apiKey = action.payload.response.apiKey
      customers.apiKeyExpiryDate = action.payload.response.apiKeyExpiry
    },

    fetchApiKeyDetailsByCustomerIdFailed: (customers) => {
      customers.loading = false
      customers.userMsg = 'Could not fetch Customer ApiKey details!'
      customers.statusCode = 500
    },

    fetchApiKeyDetailsByTenantIdSuccess: (customers, action) => {
      customers.loading = false
      customers.tenantApiKey = action.payload.response.tenantApiKey
      customers.tenantApiKeyExpiryDate = action.payload.response.tenantApiKeyExpiryDate
    },

    fetchApiKeyDetailsByTenantIdFailed: (customers) => {
      customers.loading = false
      customers.userMsg = 'Could not fetch Tenant ApiKey details!'
      customers.statusCode = 500
    },

    generateNewApiKeyForCustomerSuccess: (customers, action) => {
      customers.loading = false
      customers.apiKey = action.payload.response.newApiKey
      customers.apiKeyExpiryDate = action.payload.response.newApiExpiryDate
    },

    generateNewApiKeyForCustomerFailed: (customers) => {
      customers.loading = false
      customers.userMsg = 'Could not generate new ApiKey!'
      customers.statusCode = 500
    },

    generateNewApiKeyForTenantSuccess: (customers, action) => {
      customers.loading = false
      customers.tenantApiKey = action.payload.response.newApiKey
      customers.tenantApiKeyExpiryDate = action.payload.response.newApiExpiryDate
    },

    generateNewApiKeyForTenantFailed: (customers) => {
      customers.loading = false
      customers.userMsg = 'Could not generate new Tenant ApiKey!'
      customers.statusCode = 500
    },

    getLogoSuccess : (customers, action) => {
      customers.logo = action.payload.response.data ? action.payload.response.data : logoWithText
      customers.logoLoading = false
    },

    getLogoFailed : (customers, action) => {
      if(action.payload.startsWith("The specified blob does not exist"))
        customers.logo = logoWithText
      customers.logoLoading = false
    },
    setLicenseUserErrorMsg: (customers, action) => {
      customers.licenseUserListApiFailed = action.payload
    },
    setStatusCode: (customers, action) => {
      customers.statusCode = action.payload
    },

    getmultimodalSettingsRequested: (customers, action) => {
      customers.scanConfigSettingsLoading = true;
    },

    getMultimodalSettingsSuccess: (customers, action) => {
      let {mmEnabled, modelCode } = action.payload.response.data[0];
      customers.scanConfigSettings.mmEnabled =mmEnabled !== null ? mmEnabled : false;
      customers.scanConfigSettings.modelCode =modelCode !== null ? modelCode : 'PP1';
      customers.scanConfigSettingsLoading = false;
    },

    getMultimodalSettingsFailed : (customers, action) => {
      customers.scanConfigSettingsLoading = false;
    },

    setMultimodalSettingsRequested: (customers, action) => {

    },

    setMultimodalSettingsSuccess : (customers, action) => {
      let {mmEnabled, modelCode } = action.payload.response.data[0];
      customers.scanConfigSettings.mmEnabled =mmEnabled !== null ? mmEnabled : false;
      customers.scanConfigSettings.modelCode =modelCode !== null ? modelCode : 'PP1';
    },

    setMultimodalSettingsFailed : (customers, action) => {
      let {error} = action.payload.response.error;
      customers.scanConfigSettingsLoading = false;
      customers.scanConfigSettingsMsg = 'Error in updating multimodal settings : '+ error.message;
    },

    getWhitelistUrlsRequested: (customers, action) => {
      customers.getWhitelistUrlsFlag = true
    },

    getWhitelistUrlsSuccess: (customers, action) => {
      customers.loading = false;
      customers.getWhitelistUrlsFlag = false;
      let listWhitelistedUrls = action.payload.response.data;
      console.log("listWhitelistedUrls", listWhitelistedUrls);
      customers.whitelistUrls = listWhitelistedUrls.map((urlObj) => {
        console.log("urlObj", urlObj);
        console.log("decodeURIComponent(urlObj.url)", decodeURIComponent(urlObj.url));
        try{
          return {...urlObj, url: decodeURIComponent(urlObj.url)}
        } catch(e){
          return {...urlObj, url: "malformed URL"};
        }
        }
        ); //""//listWhitelistedDomains.sort((a, b) => (a.authorizedDomain.toLowerCase() > b.authorizedDomain.toLowerCase()) ? 1 : ((b.authorizedDomain.toLowerCase() > a.authorizedDomain.toLowerCase()) ? -1 : 0));
    },

    getWhitelistUrlsFailed: (customers) => {
      customers.loading = false;
      customers.getWhitelistUrlsFlag = false;
      customers.whitelistUrls = [];
      customers.userMsg = 'Could not fetch URL list!';
      customers.statusCode = 500;
    },

    addWhitelistUrlRequested: (customers, action) => {
      customers.addWhitelistUrlFlag = true;
    },

    addWhitelistUrlSuccess: (customers, action) => {
      customers.loading = false
      customers.addWhitelistUrlFlag = false
      customers.userMsg = 'Url added successfully'
      customers.statusCode = 200
    },
     
    addWhitelistUrlFailed: (customers, action) => {
      const { data , errors} = action.payload.response;
      customers.loading = false;
      customers.addWhitelistUrlFlag = false;
      if(errors.length > 0){
        customers.userMsg = errors[0].code && errors[0].code === "500"? "Failed to add URL": errors[0].desc; 
        customers.statusCode = 500;
      }
      customers.statusCode = 500;
    },

    removeWhitelistUrlRequested: (customers, action) => {
      customers.removeWhitelistUrlFlag = true
    },

    removeWhitelistUrlSuccess: (customers) => {
      customers.loading = false
      customers.removeWhitelistUrlFlag = false
      customers.userMsg = 'URL deleted successfully'
      customers.statusCode = 200
    },

    removeWhitelistUrlFailed: (customers) => {
      customers.loading = false;
      customers.removeWhitelistUrlFlag = false;
      customers.userMsg = 'Failed to delete URL';
      customers.statusCode = 500;
    },

    getDemoModeSettingsRequested: (customers, action) => {
      customers.scanConfigSettingsLoading = true;
    },

    getDemoModeSettingsSuccess: (customers, action) => {
      let { demomodeEnabled } = action.payload.response.data[0];
      customers.scanConfigSettings.demomodeEnabled = demomodeEnabled !== null ? demomodeEnabled : false;
      customers.scanConfigSettingsLoading = false;
    },

    getDemoModeSettingsFailed : (customers, action) => {
      customers.scanConfigSettingsLoading = false;
    },

    updateDemoModeSettingsRequested: (customers, action) => {
      // customers.scanConfigSettingsLoading = true;
    },

    updateDemoModeSettingsSuccess : (customers, action) => {
      let { demomodeEnabled } = action.payload.response.data[0];
      customers.scanConfigSettings.demomodeEnabled = demomodeEnabled !== null ? demomodeEnabled : false;
    },

    updateDemoModeSettingsFailed : (customers, action) => {
      let {error} = action.payload.response.error[0];
      customers.scanConfigSettingsLoading = false;
      customers.scanConfigSettingsMsg = 'Error in updating Demo Mode settings : '+ error.message;
    },

    getProcessPageSettingsRequested: (customers, action) => {
      customers.scanConfigSettingsLoading = true;
    },

    getProcessPageSettingsSuccess: (customers, action) => {
      let { ppEnabled } = action.payload.response.data[0];
      customers.scanConfigSettings.ppEnabled = ppEnabled !== null ? ppEnabled : true;
      customers.scanConfigSettingsLoading = false;
    },

    getProcessPageSettingsFailed : (customers, action) => {
      customers.scanConfigSettingsLoading = false;
    },

    updateProcessPageSettingsRequested: (customers, action) => {
      // customers.scanConfigSettingsLoading = true;
    },

    updateProcessPageSettingsSuccess : (customers, action) => {
      let { ppEnabled } = action.payload.response.data[0];
      customers.scanConfigSettings.ppEnabled = ppEnabled !== null ? ppEnabled : true;
    },

    updateProcessPageSettingsFailed : (customers, action) => {
      let {error} = action.payload.response.error[0];
      customers.scanConfigSettingsLoading = false;
      customers.scanConfigSettingsMsg = 'Error in updating Process Page settings : '+ error.message;
    },

  }
})
function isTenantExist (obj, tenantId) {
  return obj.hasOwnProperty(tenantId);
}

export const { customerAdded,
  deleteCustomerSuccess,
  usersListSuccess,
  updateCustomerSuccess,
  usersListFailed,
  updateCustomerFailed,
  deleteCustomerFailed,
  createCustomerSuccess,
  createCustomerFailed,
  customersRequested,
  beforeGetCustomerList,
  onErrorGetCustomerList,
  registeredUsersRequested,
  newUsersRequested,
  activeUsersRequested,
  extensionUsersRequested,
  detectedUrlBreakdownsRequested,
  verifiedUrlBreakdownsRequested,
  urlUserBreakdownsRequested,
  breakDownsRequested,
  customersRequestedOnSearch,
  customersReceived,
  // customersReceivedPixmAdmin,
  customersRequestFailed,
  updateCustomer,
  callGetUsersOnce,
  brandsReceived,
  brandsFailed,
  extensionUsersReceived,
  searchedExtensionUsersReceived,
  activeUsersCountReceived,
  extensionUsersCountReceived,
  breakDownsReceived,
  detectedUrlBreakdownsReceived,
  verifiedUrlBreakdownsReceived,
  urlUserBreakdownsReceived,
  activeUsersCountFailed,
  extensionUsersCountFailed,
  breakDownsFailed,
  detectedUrlBreakdownsFailed,
  verifiedUrlBreakdownsFailed,
  urlUserBreakdownsFailed,
  extensionUsersFailed,
  extensionUserByIdReceived,
  inviteAdminSuccess,
  inviteAdminFailed,
  removeAdminSuccess,
  removeAdminFailed,
  extensionUserByIdFailed,
  resetUser,
  nextUsersReceived,
  userListRequested,
  usersReceived,
  deployedLicensesGrowthRequested,
  deployedLicensesGrowthReceived,
  activeUserGrowthReceived,
  newUsersReceived,
  registeredUsersReceived,
  usersReceivedBySearch,
  usersFailed,
  deployedLicensesGrowthFailed,
  activeUserGrowthFailed,
  registeredUsersFailed,
  newUsersFailed,
  usersRequestFailed,
  pervUsersReceived,
  cacheHit,
  updateTenantInfoSuccess,
  updateTenantInfoIsCustomizedSuccess,
  updateTenantInfoWhitelabelSuccess,
  updateTenantInfoIsCustomizedFailure,
  updateTenantInfoWhitelabelFailure,
  updateTenantInfoFailed,
  cancelAccountSuccess,
  cancelAccountFailed,
  getTenantDetailsSuccess,
  getTenantInfoWhitelabelSuccess,
  getTenantDetailsFailed,
  getCustomerDetailsSuccess,
  getCustomerDetailsFailed,
  updateUserInfoSuccess,
  updateUserInfoFailed,
  resetModalSuccess,
  resetSearchedUsers,
  setCustomError,
  setCustomInfo,
  // updateCustomerList,
  customerAlertConfigRequested,
  getCustomerAlertConfigSuccess,
  getCustomerAlertConfigFailed,
  updateCustomerAlertConfigRequested,
  updateCustomerAlertConfigSuccess,
  updateCustomerAlertConfigFailed,
  whitelistDomainRequested,
  whitelistDomainSuccess,
  whitelistDomainFailed,
  removeWhitelistDomainRequested,
  removeWhitelistDomainSuccess,
  removeWhitelistDomainFailed,
  addWhitelistDomainRequested,
  addWhitelistDomainSuccess,
  addCustomerSuccess,
  addCustomerFailed,
  addWhitelistDomainFailed,
  blacklistUrlsRequested,
  blacklistUrlsSuccess,
  blacklistUrlsFailed,
  removeBlacklistUrlsRequested,
  removeBlacklistUrlsSuccess,
  removeBlacklistUrlsFailed,
  addBlacklistUrlRequested,
  addBlacklistUrlSuccess,
  addBlacklistUrlFailed,
  fetchApiKeyDetailsByCustomerIdSuccess,
  fetchApiKeyDetailsByCustomerIdFailed,
  fetchApiKeyDetailsByTenantIdSuccess,
  fetchApiKeyDetailsByTenantIdFailed,
  generateNewApiKeyForCustomerSuccess,
  generateNewApiKeyForCustomerFailed,
  generateNewApiKeyForTenantSuccess,
  generateNewApiKeyForTenantFailed,
  setCustomersFilters,
  getLogoSuccess,
  getLogoFailed,
  setLicenseUserErrorMsg,
  setStatusCode,
  getmultimodalSettingsRequested,
  getMultimodalSettingsSuccess,
  getMultimodalSettingsFailed,
  setMultimodalSettingsRequested,
  setMultimodalSettingsSuccess,
  setMultimodalSettingsFailed,
  getDemoModeSettingsFailed,
  getDemoModeSettingsRequested,
  getDemoModeSettingsSuccess,
  updateDemoModeSettingsRequested,
  updateDemoModeSettingsSuccess,
  updateDemoModeSettingsFailed,
  getProcessPageSettingsRequested,
  getProcessPageSettingsSuccess,
  getProcessPageSettingsFailed,
  updateProcessPageSettingsRequested,
  updateProcessPageSettingsSuccess,
  updateProcessPageSettingsFailed,
  getWhitelistUrlsRequested,
  getWhitelistUrlsSuccess,
  getWhitelistUrlsFailed,
  addWhitelistUrlRequested,
  addWhitelistUrlSuccess,
  addWhitelistUrlFailed,
  removeWhitelistUrlRequested,
  removeWhitelistUrlSuccess,
  removeWhitelistUrlFailed,
 } = slice.actions
export default slice.reducer

// export const loadCustomers = (customers, action) => {
//   const { records, filters, totalRecords } = action.payload.response
//   if (setDefaultElement && role !== 'PixmAdmin') {
//     // as informed by Chris, Global View for MSP has been commented till functionality has been fully implemented [Date: 28-Feb-2024]
//     // if (role === "TenantAdmin" && customers.customers && !customers.customers.some((item) => item.id === customers.tenants[0].tenantId)) {
//     //   customers.customers.splice(0, 0, addGlobalViewAsCustomer(customers.tenants[0]));
//     // }
//     return customersReceived(customers)
//   } else if (setDefaultElement && role === 'PixmAdmin') {
//     return customersReceivedPixmAdmin(customers)
//   } else {
//     return updateCustomerList(customers)
//   }
// }


export const getBrands = (selectedId, defaultCustomerId,  isGlobalView) => {
  return (dispatch, getState) => {
    // const customersUnderTA = getCustomersUnderTA(getState)
    const url = `portal/${isGlobalView ? 'tenants' : 'customers'}/${selectedId}/brands`;
    return dispatch(apiCallBegan({
      urlPath: url,
      method: 'GET',
      filters: isGlobalView ? {customerId: defaultCustomerId} : {},
      onStart: customersRequested.type,
      onSuccess: brandsReceived.type,
      onError: brandsFailed.type,
    }))
  }
}

export const getOnceUsers = () => {
  return callGetUsersOnce();
}

// export const getExtensionUsers = (selectedId, isGlobalView) => {
//   return (dispatch, getState) => {
//     const customersUnderTA = getCustomersUnderTA(getState)
//     const url = `portal/${isGlobalView ? 'tenants' : 'customers'}/${selectedId}/extension-users`;
//     return dispatch(apiCallBegan({
//       urlPath: url,
//       method: 'GET',
//       filters: isGlobalView ? {customerId: customersUnderTA[1]?.id} : {}, 
//       onStart: customersRequested.type,
//       onSuccess: extensionUsersReceived.type,
//       onError: extensionUsersFailed.type,
//     }))
//   }
// }

export const getSearchedExtensionUsers = (selectedId, searchTerm, key, isGlobalView) => {
  return (dispatch, getState) => {
    const customersUnderGlobalView = getCustomerForGlobalView(getState, selectedId, key);
    // const customersUnderTA = getCustomersUnderTA(getState)
    const url = `portal/${isGlobalView ? 'tenants' : 'customers'}/${selectedId}/search-extension-users`;
    return dispatch(apiCallBegan({
      urlPath: url,
      method: 'GET',
      filters: isGlobalView ? { customerId: customersUnderGlobalView, term: searchTerm } : { term: searchTerm },
      onStart: customersRequestedOnSearch.type,
      onSuccess: searchedExtensionUsersReceived.type,
      onError: extensionUsersFailed.type,
    }))
  }
}

export const getUsers = (filters, selectedId, defaultCustomerId, isGlobalView) => {
  return (dispatch, getState) => {
    // const customersUnderTA = getCustomersUnderTA(getState)
    const url = `portal/${isGlobalView ? 'tenants' : 'customers'}/${selectedId}/extension-users`;
    return dispatch(apiCallBegan({
      urlPath: url,
      method: 'GET',
      filters: isGlobalView ? {...filters, customerId: defaultCustomerId} : filters,
      onStart: userListRequested.type,
      onSuccess: usersReceived.type,
      onError: usersFailed.type,
    }))
  }
}

export const getDeployedLicensesGrowth = (filters, selectedId, defaultCustomerId, isGlobalView) => {
  return (dispatch, getState) => {
    // const customersUnderTA = getCustomersUnderTA(getState)
    const url = `dashboard/${isGlobalView ? 'tenants' : 'customers'}/${selectedId}/deployed-licenses-growth`;
    return dispatch(apiCallBegan({
      urlPath: url,
      method: 'GET',
      filters: isGlobalView ? {...filters, customerId: defaultCustomerId} : filters,
      onStart: deployedLicensesGrowthRequested.type,
      onSuccess: deployedLicensesGrowthReceived.type,
      onError: deployedLicensesGrowthFailed.type,
      reducerData: filters
    }))
  }
}

export const getActiveUserGrowth = (filters, selectedId, isGlobalView) => {
  const url = `dashboard/${isGlobalView ? 'tenants' : 'customers'}/${selectedId}/active-user-growth`;
  return apiCallBegan({
    urlPath: url,
    method: 'GET',
    filters,
    onStart: customersRequested.type,
    onSuccess: activeUserGrowthReceived.type,
    onError: activeUserGrowthFailed.type,
  })
}

export const getRegisteredUsers = (filters, selectedId, isGlobalView) => {
  const url = `dashboard/${isGlobalView ? 'tenants' : 'customers'}/${selectedId}/all-extension-users`;
  return apiCallBegan({
    urlPath: url,
    method: 'GET',
    filters,
    onStart: registeredUsersRequested.type,
    onSuccess: registeredUsersReceived.type,
    onError: registeredUsersFailed.type,
  })
}

export const getNewUsers = (filters, selectedId, isGlobalView) => {
  const url = `dashboard/${isGlobalView ? 'tenants' : 'customers'}/${selectedId}/extension-users`;
  return apiCallBegan({
    urlPath: url,
    method: 'GET',
    filters,
    onStart: newUsersRequested.type,
    onSuccess: newUsersReceived.type,
    onError: newUsersFailed.type,
  })
}

// export const loadNextUsers = (filters, selectedId, isGlobalView) => {
//   console.log('loadNextUsers');
//   return (dispatch, getState) => {
//     const customersUnderTA = getCustomersUnderTA(getState)
//     const url = `portal/${isGlobalView ? 'tenants' : 'customers'}/${selectedId}/extension-users`;
//     return dispatch(cacheCallBegan({
//       urlPath: url,
//       method: 'GET',
//       filters: isGlobalView ? {...filters, customerId: customersUnderTA[1]?.id} : filters,
//       onStart: customersRequested.type,
//       onSuccess: nextUsersReceived.type,
//       onError: usersRequestFailed.type,
//       onHit: cacheHit.type,
//     }))
//   }
// }

// export const loadPrevUsers = (filters, selectedId, isGlobalView) => {
//   console.log('loadPrevUsers');
//   return (dispatch, getState) => {
//     const customersUnderTA = getCustomersUnderTA(getState)
//     const url = `portal/${isGlobalView ? 'tenants' : 'customers'}/${selectedId}/extension-users`;
//     return dispatch(cacheCallBegan({
//       urlPath: url,
//       method: 'GET',
//       filters: isGlobalView ? {...filters, customerId: customersUnderTA[1]?.id} : filters,
//       onStart: customersRequested.type,
//       onSuccess: pervUsersReceived.type,
//       onError: usersRequestFailed.type,
//       onHit: cacheHit.type,
//     }))
//   }
// }

export const getExtensionUserById = (customerId, userId, isGlobalView) => {
  return apiCallBegan({
    //urlPath: `/portal/${isGlobalView ? 'tenants' : 'customers'}/${customerId}/extension-users/${userId}`,
    urlPath: `/portal/customers/${customerId}/extension-users/${userId}`,
    method: 'GET',
    onStart: customersRequested.type,
    onSuccess: extensionUserByIdReceived.type,
    onError: extensionUserByIdFailed.type,
  })
}

export const getSearchExtensionUserById = (customerId, userId) => {
  return apiCallBegan({
    urlPath: `/portal/customers/${customerId}/extension-users/${userId}`,
    method: 'GET',
    onStart: userListRequested.type,
    onSuccess: usersReceivedBySearch.type,
    onError: usersFailed.type,
  })
}

export const getActiveUsersCount = (selectedId, isGlobalView) => {
  const url = `portal/${isGlobalView ? 'tenants' : 'customers'}/${selectedId}/active-users-count`;
  return apiCallBegan({
    urlPath: url,
    method: 'GET',
    onStart: activeUsersRequested.type,
    onSuccess: activeUsersCountReceived.type,
    onError: activeUsersCountFailed.type,
  })
}

export const getExtensionUsersCount = (selectedId, defaultCustomerId, isGlobalView) => {
  return (dispatch, getState) => {
    // const customersUnderTA = getCustomersUnderTA(getState)
    const url = `portal/${isGlobalView ? 'tenants' : 'customers'}/${selectedId}/licenses-count`;
    return dispatch(apiCallBegan({
      urlPath: url,
      method: 'GET',
      filters: isGlobalView ? {customerId: defaultCustomerId} : {},
      onStart: extensionUsersRequested.type,
      onSuccess: extensionUsersCountReceived.type,
      onError: extensionUsersCountFailed.type,
    }))
  }
}

export const getBreakDowns = (selectedId, defaultCustomerId, isGlobalView) => {
  return (dispatch, getState) => {
    // const customersUnderTA = getCustomersUnderTA(getState)
    const url = `dashboard/${isGlobalView ? 'tenants' : 'customers'}/${selectedId}/license-count`;
    return dispatch(apiCallBegan({
      urlPath: url,
      method: 'GET',
      filters: isGlobalView ? {customerId: defaultCustomerId} : {},
      onStart: breakDownsRequested.type,
      onSuccess: breakDownsReceived.type,
      onError: breakDownsFailed.type,
    }))
  }
}

export const getDetectedUrlBreakdowns = (filters, selectedId, isGlobalView) => {
  const url = `dashboard/${isGlobalView ? 'tenants' : 'customers'}/${selectedId}/daily/all-events`;
  return apiCallBegan({
    urlPath: url,
    method: 'GET',
    filters,
    onStart: detectedUrlBreakdownsRequested.type,
    onSuccess: detectedUrlBreakdownsReceived.type,
    onError: detectedUrlBreakdownsFailed.type,
  })
}

export const getVerifiedUrlBreakdowns = (filters, selectedId, isGlobalView) => {
  const url = `dashboard/${isGlobalView ? 'tenants' : 'customers'}/${selectedId}/verify-events`;
  return apiCallBegan({
    urlPath: url,
    method: 'GET',
    filters,
    onStart: verifiedUrlBreakdownsRequested.type,
    onSuccess: verifiedUrlBreakdownsReceived.type,
    onError: verifiedUrlBreakdownsFailed.type,
  })
}

export const getUrlUsersBreakdowns = (filters, selectedId, isGlobalView) => {
  const url = `dashboard/${isGlobalView ? 'tenants' : 'customers'}/${selectedId}/url-users`;
  return apiCallBegan({
    urlPath: url,
    method: 'GET',
    filters,
    onStart: urlUserBreakdownsRequested.type,
    onSuccess: urlUserBreakdownsReceived.type,
    onError: urlUserBreakdownsFailed.type,
  })
}

export const setCurrentCustomer = (customer) => {
  return updateCustomer(customer);
}

export const resetExtensionUser = () => {
  return resetUser();
}

export const getCustomerList = () => {
  return apiCallBegan({
    urlPath: `/portal/customers`,
    method: 'GET',
    onStart: beforeGetCustomerList.type,
    onSuccess: customersReceived.type,
    onError: onErrorGetCustomerList.type,
  })
}

export const createCustomer = (body) => {
  return apiCallBegan({
    urlPath: `/portal/customers`,
    data: body,
    method: 'POST',
    onStart: customersRequested.type,
    onSuccess: createCustomerSuccess.type,
    onError: createCustomerFailed.type,
  })
}

export const deleteCustomer = (customerId) => {
  return apiCallBegan({
    urlPath: `/msp-management/customers/${customerId}`,
    method: 'DELETE',
    onStart: customersRequested.type,
    onSuccess: deleteCustomerSuccess.type,
    onError: deleteCustomerFailed.type,
  })
}

export const updateSelectedCustomer = (customerId, body) => {
  return apiCallBegan({
    urlPath: `/msp-management/customers/${customerId}`,
    data: body,
    method: 'PUT',
    onStart: customersRequested.type,
    onSuccess: updateCustomerSuccess.type,
    onError: updateCustomerFailed.type,
  })
}

export const getUsersList = (gcipTenantId, customerId, roleType) => {
  return apiCallBegan({
    urlPath: `/users${gcipTenantId && customerId && roleType ? `?gcipTenantId=${gcipTenantId}&customerId=${customerId}&roleType=${roleType}` : ''}${!gcipTenantId && customerId && roleType ? `?customerId=${customerId}&roleType=${roleType}` : ''}${!gcipTenantId && !customerId && roleType ? `?roleType=${roleType}` : ''}${!gcipTenantId && !customerId && !roleType ? '' : ''}`,
    method: 'GET',
    onStart: customersRequested.type,
    onSuccess: usersListSuccess.type,
    onError: usersListFailed.type,
  })
}

export const removeAdmin = (userId) => {
  return apiCallBegan({
    urlPath: `/users/${userId}`,
    method: 'DELETE',
    onStart: customersRequested.type,
    onSuccess: removeAdminSuccess.type,
    onError: removeAdminFailed.type,
  })
}



export const inviteAdmin = (body) => {
  return apiCallBegan({
    urlPath: `auth/invite-user`,
    data: body,
    method: 'POST',
    onStart: customersRequested.type,
    onSuccess: inviteAdminSuccess.type,
    onError: inviteAdminFailed.type,
  })
}

export const updateTenantInfo = (tenantId, body) => {
  return apiCallBegan({
    urlPath: `msp-management/tenants/${tenantId}`,
    data: body,
    method: 'PUT',
    onStart: customersRequested.type,
    onSuccess: updateTenantInfoSuccess.type,
    onError: updateTenantInfoFailed.type,
  })
}

export const updateTenantInfoIsCustomized = (tenantId, body) => {
  return apiCallBegan({
    urlPath: `msp-management/tenants/${tenantId}`,
    data: body,
    method: 'PUT',
    onStart: customersRequested.type,
    onSuccess: updateTenantInfoIsCustomizedSuccess.type,
    onError: updateTenantInfoIsCustomizedFailure.type,
  })
}

export const createTenantInfoWhitelabel = (tenantId, body) => {
  return apiCallBegan({
    urlPath: `portal/tenants/${tenantId}/settings/whitelabel`,
    data: body,
    method: 'POST',
    onStart: customersRequested.type,
    onSuccess: updateTenantInfoSuccess.type,
    onError: updateTenantInfoFailed.type,
  })
}

export const updateTenantInfoWhitelabel = (tenantId, body) => {
  return apiCallBegan({
    urlPath: `portal/tenants/${tenantId}/settings/whitelabel`,
    data: body,
    method: 'PUT',
    onStart: customersRequested.type,
    onSuccess: updateTenantInfoWhitelabelSuccess.type,
    onError: updateTenantInfoWhitelabelFailure.type,
  })
}

export const deleteTenantInfoWhitelabel = (tenantId, body) => {
  return apiCallBegan({
    urlPath: `portal/tenants/${tenantId}/settings/whitelabel/delete`,
    data: body,
    method: 'PUT',
    onStart: customersRequested.type,
    onSuccess: updateTenantInfoSuccess.type,
    onError: updateTenantInfoFailed.type,
  })
}

export const cancelAccount = (tenantId) => {
  return apiCallBegan({
    urlPath: `msp-management/tenants/${tenantId}`,
    method: 'DELETE',
    onStart: customersRequested.type,
    onSuccess: cancelAccountSuccess.type,
    onError: cancelAccountFailed.type,
  })
}

export const getTenantDetails = (tenantId) => {
  return apiCallBegan({
    urlPath: `msp-management/tenants/${tenantId}`,
    method: 'GET',
    onStart: customersRequested.type,
    onSuccess: getTenantDetailsSuccess.type,
    onError: getTenantDetailsFailed.type,
  })
}

export const getTenantWhitelabelInfo = (tenantId) => {
  return apiCallBegan({
    urlPath: `portal/tenants/${tenantId}/settings/whitelabel`,
    method: 'GET',
    onStart: customersRequested.type,
    onSuccess: getTenantInfoWhitelabelSuccess.type,
    onError: getTenantDetailsFailed.type,
  })
}

export const getCustomerDetails = (customerId) => {
  return apiCallBegan({
    urlPath: `portal/customers/${customerId}`,
    method: 'GET',
    onStart: customersRequested.type,
    onSuccess: getCustomerDetailsSuccess.type,
    onError: getCustomerDetailsFailed.type,
  })
}

export const updateUserInfo = (userId, body) => {
  return apiCallBegan({
    urlPath: `/users/${userId}`,
    data: body,
    method: 'PUT',
    onStart: customersRequested.type,
    onSuccess: updateUserInfoSuccess.type,
    onError: updateUserInfoFailed.type,
  })
}

export const addCustomer = (body) => {
  return apiCallBegan({
    urlPath: `auth/customer/signup`,
    data: body,
    method: 'POST',
    onSuccess: addCustomerSuccess.type,
    onError: addCustomerFailed.type,
  })
}

export const getCustomerAlertConfig = (customerId) => {
  return apiCallBegan({
    urlPath: `portal/customers/${customerId}/alert-configurations`,
    method: 'GET',
    onStart: customerAlertConfigRequested.type,
    onSuccess: getCustomerAlertConfigSuccess.type,
    onError: getCustomerAlertConfigFailed.type,
  })
}

export const updateCustomerAlertConfig = (customerId, body) => {
  return apiCallBegan({
    urlPath: `portal/customers/${customerId}/alert-configurations`,
    data: body,
    method: 'PUT',
    onStart: updateCustomerAlertConfigRequested.type,
    onSuccess: updateCustomerAlertConfigSuccess.type,
    onError: updateCustomerAlertConfigFailed.type
  })
}

export const fetchApiKeyDetailsByCustomerId = (customerId) => {
  return apiCallBegan({
    urlPath: `/enterprise-soc/fetch-apikey/${customerId}`,
    method: 'GET',
    onStart: customersRequested.type,
    onSuccess: fetchApiKeyDetailsByCustomerIdSuccess.type,
    onError: fetchApiKeyDetailsByCustomerIdFailed.type
  })
}

export const generateNewApiKeyForCustomer = (customerId) => {
  return apiCallBegan({
    urlPath: `/enterprise-soc/generate-apikey/${customerId}`,
    method: 'PUT',
    onStart: customersRequested.type,
    onSuccess: generateNewApiKeyForCustomerSuccess.type,
    onError: generateNewApiKeyForCustomerFailed.type
  })
}

export const fetchApiKeyDetailsByTenantId = (tenantId) => {
  return apiCallBegan({
    urlPath: `/enterprise-soc/fetch-tenant-apikey/${tenantId}`,
    method: 'GET',
    onStart: customersRequested.type,
    onSuccess: fetchApiKeyDetailsByTenantIdSuccess.type,
    onError: fetchApiKeyDetailsByTenantIdFailed.type
  })
}

export const generateNewApiKeyForTenant = (tenantId) => {
  return apiCallBegan({
    urlPath: `/enterprise-soc/generate-tenant-apikey/${tenantId}`,
    method: 'PUT',
    onStart: customersRequested.type,
    onSuccess: generateNewApiKeyForTenantSuccess.type,
    onError: generateNewApiKeyForTenantFailed.type
  })
}

export const resetModal = () => {
  return resetModalSuccess()
}

export const setError = (error) => {
  return setCustomError(error)
}

export const setInfo = (info) => {
  return setCustomInfo(info)
}

export const clearSearchedUsers = () => {
  return resetSearchedUsers()
}

export const getWhitelistDomains = (customerId) => {
  return apiCallBegan({
    urlPath: `portal/customers/${customerId}/whitelist-domain`,
    method: 'GET',
    onStart: whitelistDomainRequested.type,
    onSuccess: whitelistDomainSuccess.type,
    onError: whitelistDomainFailed.type,
  })
}

export const removeWhitelistDomain = (customerId, whitelistDomainId) => {
  return apiCallBegan({
    urlPath: `portal/customers/${customerId}/whitelist-domain/${whitelistDomainId}`,
    method: 'DELETE',
    onStart: removeWhitelistDomainRequested.type,
    onSuccess: removeWhitelistDomainSuccess.type,
    onError: removeWhitelistDomainFailed.type,
  })
}

export const addWhitelistDomain = (customerId, body) => {
  return apiCallBegan({
    urlPath: `portal/customers/${customerId}/whitelist-domain`,
    data: body,
    method: 'POST',
    onStart: addWhitelistDomainRequested.type,
    onSuccess: addWhitelistDomainSuccess.type,
    onError: addWhitelistDomainFailed.type,
  })
}

export const getBlacklistUrls = (customerId, body) => {
  return apiCallBegan({
    urlPath: `portal/customers/${customerId}/blacklist-urls`,
    data: body,
    method: 'GET',
    onStart: blacklistUrlsRequested.type,
    onSuccess: blacklistUrlsSuccess.type,
    onError: blacklistUrlsFailed.type,
  })
}

export const removeBlacklistUrls = (customerId, blacklistUrlId) => {
  return apiCallBegan({
    urlPath: `portal/customers/${customerId}/blacklist-urls/${blacklistUrlId}`,
    method: 'DELETE',
    onStart: removeBlacklistUrlsRequested.type,
    onSuccess: removeBlacklistUrlsSuccess.type,
    onError: removeBlacklistUrlsFailed.type,
  })
}

export const addBlacklistUrl = (customerId, body) => {
  return apiCallBegan({
    urlPath: `portal/customers/${customerId}/blacklist-urls`,
    data: body,
    method: 'POST',
    onStart: addBlacklistUrlRequested.type,
    onSuccess: addBlacklistUrlSuccess.type,
    onError: addBlacklistUrlFailed.type,
  })
}

export const getLogo = (body) => {
  return apiCallBegan({
    urlPath: `portal/file/download`,
    data: body,
    method: 'POST',
    onSuccess: getLogoSuccess.type,
    onError: getLogoFailed.type,
  })
}

export const getMultimodalSettings = (customerId) => {
  return apiCallBegan({
    urlPath: `portal/multimodal-setting/${customerId}`,
    method: 'GET',
    onStart: getmultimodalSettingsRequested.type,
    onSuccess: getMultimodalSettingsSuccess.type,
    onError: getMultimodalSettingsFailed.type,
  })
}

export const updateMultimodalSettings = (customerId, body) => {
  return apiCallBegan({
    urlPath: `portal/multimodal-setting/${customerId}`,
    data: body,
    method: 'PUT',
    onStart: getmultimodalSettingsRequested.type,
    onSuccess: getMultimodalSettingsSuccess.type,
    onError: getMultimodalSettingsFailed.type,
  })
}

export const getDemoModeSettings = (customerId) => {
  return apiCallBegan({
    urlPath: `portal/demomode-setting/${customerId}`,
    method: 'GET',
    onStart: getDemoModeSettingsRequested.type,
    onSuccess: getDemoModeSettingsSuccess.type,
    onError: getDemoModeSettingsFailed.type,
  })
}

export const updateDemoModeSettings = (customerId, body) => {
  return apiCallBegan({
    urlPath: `portal/demomode-setting/${customerId}`,
    data: body,
    method: 'PUT',
    onStart: updateDemoModeSettingsRequested.type,
    onSuccess: updateDemoModeSettingsSuccess.type,
    onError: updateDemoModeSettingsFailed.type,
  })
}

export const getProcessPageSettings = (customerId) => {
  return apiCallBegan({
    urlPath: `portal/processpage-setting/${customerId}`,
    method: 'GET',
    onStart: getProcessPageSettingsRequested.type,
    onSuccess: getProcessPageSettingsSuccess.type,
    onError: getProcessPageSettingsFailed.type,
  })
}

export const updateProcessPageSettings = (customerId, body) => {
  return apiCallBegan({
    urlPath: `portal/processpage-setting/${customerId}`,
    data: body,
    method: 'PUT',
    onStart: updateProcessPageSettingsRequested.type,
    onSuccess: updateProcessPageSettingsSuccess.type,
    onError: updateProcessPageSettingsFailed.type,
  })
}

export const getWhitelistUrls = (customerId) => {
  return apiCallBegan({
    urlPath: `portal/customers/${customerId}/whitelist-urls`,
    method: 'GET',
    onStart: getWhitelistUrlsRequested.type,
    onSuccess: getWhitelistUrlsSuccess.type,
    onError: getWhitelistUrlsFailed.type,
  })
}

export const removeWhitelistUrl = (customerId, id) => {
  return apiCallBegan({
    urlPath: `portal/customers/${customerId}/whitelist-url`,
    data: { whitelistedUrlId: id },
    method: 'DELETE',
    onStart: removeWhitelistUrlRequested.type,
    onSuccess: removeWhitelistUrlSuccess.type,
    onError: removeWhitelistUrlFailed.type,
  })
}

export const addWhitelistUrl = (customerId, body) => {
  return apiCallBegan({
    urlPath: `portal/customers/${customerId}/whitelist-url`,
    data: body,
    method: 'POST',
    onStart: addWhitelistUrlRequested.type,
    onSuccess: addWhitelistUrlSuccess.type,
    onError: addWhitelistUrlFailed.type,
  })
}

const getCustomersUnderTA = (getState) => {
  const currentState = getState();
  const currentList = currentState.entities.customers.list;
  return currentList;
}

const getCustomerForGlobalView = (getState, selectedId, key) => {
  const currentState = getState();
  const currentList = currentState.entities.customers.list;
  let record = currentList.find(ele => ele.key === key);
  return record.defaultCustomerId;
}

export const unSetLicenseUserListFailMsg = () => {
  return setLicenseUserErrorMsg(false)
}

const checkForMultiGroup  = (customers) => {
  return customers.map(customer => {
    if (getFilteredCustomers(customer, customers).length > 1 && customer.organizationName == globalViewText) {
      customer.multiGroup = true;
    }
  return customer;
  });
}

const getFilteredCustomers = (selectedCustomer, orgList) => {
  const tenantId = selectedCustomer.tenantId
  return orgList.filter(customer => customer.tenantId === tenantId && customer.organizationName == globalViewText);
}

export const unSetStatusCode = () => {
  return setStatusCode(null);
}


const addGlobalView = (customers, role, tenants) => {
  let isMSPLogin = role === 'TenantAdmin';
  const globalView = {};

  // Array to store the output
  let result = [];

  // Create a map of tenants for faster lookups
  const tenantMap = tenants.reduce((acc, tenant) => {
    acc[tenant.tenantId] = tenant;
    return acc;
  }, {});

  // Create the global view and add it to the result array
  customers.forEach(customer => {
    const groupKey = `${customer.tenantId}-${customer.groupId}`;
    const tenant = tenantMap[customer.tenantId];
    
    // if (!globalView[groupKey]) {
    if (!globalView[groupKey] && (!tenant.hideGlobalView || tenant.hideGlobalView === false)) {
      if (!globalView[customer.tenantId]) {
        globalView[customer.tenantId] = 1;
      }
      const groupNum = globalView[customer.tenantId]++;

      globalView[groupKey] = getGlobalViewDetails(customer, groupKey, groupNum, isMSPLogin);
      result.push(globalView[groupKey]);
    }

    // Add the customer object to the result array
    customer.key = customer.id;
    customer.displayName = `${ isMSPLogin ? `` : customer.tenantName+' > ' } ${customer.organizationName}`
    
    result.push(customer);
  });

  return result;
}

const getGlobalViewDetails = (customer, groupKey, groupNum, isMSPLogin) => {
  return {
    "id": customer.tenantId,
    "isActive": true,
    "isProvisioned": true,
    "created": null,
    "organizationName": globalViewText,
    "tenantId": customer.tenantId,
    "firstName": globalViewText,
    "lastName": "",
    "email": customer.email || "",
    "phone": null,
    "groupId": customer.groupId, // This is the dbHash
    "multiGroup": false,
    "displayName" : `${isMSPLogin ? `` : customer.tenantName+' > ' } ${globalViewText} ${groupNum}`,
    "key" : groupKey+groupNum,
    "defaultCustomerId" : customer.id, // This is the default customerId for database connection on backend
  };  
}

const sortCustomers = (customersList) => {
  return customersList.sort((a, b) => (a.sortKeyword.toLowerCase() > b.sortKeyword.toLowerCase()) ? 1 : ((b.sortKeyword.toLowerCase() > a.sortKeyword.toLowerCase()) ? -1 : 0));
};

const findGroupByGroupId = (customers, groupId) => {
  return customers.filter(customer => customer.groupId === groupId && customer.organizationName === globalViewText)[0];
}

const addSortKeyWord = (customers) => {
  return customers.map(customer => {
    if(customer.organizationName != globalViewText){
      let group = findGroupByGroupId(customers, customer.groupId)
      // customer.sortKeyword = `${group.displayName}${customer.displayName}`;
      customer.sortKeyword = group ? `${group.displayName}${customer.displayName}` : `${customer.displayName}`;
    }
    else{
      customer.sortKeyword =`${customer.displayName}`
    }
    
    return customer;
  });
}
