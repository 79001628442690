import React, { Component } from 'react'
import Style from './AdminManagement.module.css'
import { connect } from 'react-redux'
import { formatDetectionDatetime } from '../utilities/datetime'
import { withRouter } from 'react-router-dom';
import ModalPopup from './common/ModalPopup'
import LoaderWrapper from './common/LoaderWrapper/LoaderWrapper';
import DashIcon from '../assets/minus.png' 
import { getWhitelistUrls, removeWhitelistUrl } from '../store/entities/customers'
import Tooltip from './common/popover/tooltip'

class WhitelistUrl extends Component {

    componentDidMount() {
        if(this.props.selectedCustomer) {
            this.props.getWhitelistUrls(this.props.selectedCustomer.id)
        }    
    }

    componentDidUpdate(prevProps) {
        if(prevProps.addWhitelistUrlFlag === true && this.props.addWhitelistUrlFlag === false) {
            this.props.getWhitelistUrls(this.props.selectedCustomer.id)
        }
        if(prevProps.removeWhitelistUrlFlag === true && this.props.removeWhitelistUrlFlag === false) {
            this.props.getWhitelistUrls(this.props.selectedCustomer.id)
        }
    }

    handleRemoveUrl = (whitelistUrlId, customerId) => {      
            this.props.removeWhitelistUrl( customerId, whitelistUrlId);
    }

    handleAddUrl = () => {
        this.props.history.push({pathname: `/customers/${this.props.selectedCustomer.id}/settings/add-whitelist-url`}) 
    }

    render() {
        let { whitelistUrls } = this.props
        return (
            <div>
                <div className={' mt-4 w-100'}>
                    <div className={`${Style.titleStyle}`}>
                        Allowed Google Form URLs
                    </div>
                    <div className={`${Style.shortInfoStyle} w-100 d-flex align-items-center mt-3 px-5`}>
                        <div style={{width: '80%'}} className={`${Style.borderStyle} px-4`}>
                            <div className={`${Style.fontBold}`}>
                                Current Custom URLs
                            </div>
                            <div className={`${Style.currentAdminCount}`}>
                            {whitelistUrls.length}
                            </div>
                        </div>
                        <div style={{width: '20%'}} className={'ml-4'}>
                            <div className={`${Style.fontBold}`}>
                            Add Allowed URL
                            </div>
                            <div className={'d-flex mt-3'}>
                                <button className={`${Style.submitButton} focus-none px-4`} onClick={this.handleAddUrl}>Add URL</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'mt-5'}>
                    <div className={Style.adminsStyle}>
                    Google Form URL
                    </div>
                    <div className={'mt-3'}>
                        <div className={`d-flex px-5 mx-2`}>
                            <div className={`${Style.fontBold}`} style={{width:'18%'}}><b>Name</b></div>
                            <div className={`${Style.fontBold}`} style={{width:'60%'}}><b>URL</b></div>
                            <div className={`${Style.fontBold}`} style={{width:'22%'}}><b>Action</b></div>
                        </div>
                        <div className={`${Style.tableContainerStyle} p-4 mt-3`}>
                            <div className={`${Style.tableStyle} ${Style.ScrollStyle}`}>
                                {
                                    // this.props.getWhitelistUrlsFlag 
                                    this.props.getWhitelistUrlsFlag ?
                                        <LoaderWrapper loading={this.props.getWhitelistUrlsFlag} isFlex={true} styles={{top: '50px'}} waitForData={false}></LoaderWrapper>
                                    :
                                    whitelistUrls.map((item, index) => {
                                    return <div key={item.uid} className={`${ index%2 === 0 ? Style.rowBg : Style.customRowBg} px-4 py-2`}>
                                            <div className={`d-flex p-2`}>
                                                <div className={`${Style.fontBold}`} style={{width:'18%'}}>
                                                    {item.displayName || "Pixm Admin"}
                                                </div>
                                                <div style={{width:'60%'}}>
                                                    <div className={'text-truncate'} style={{ width: '90%' }}>
                                                        <div
                                                            className={'textWrap urlWrap'}
                                                            data-tip
                                                            data-for={item.url}
                                                        >
                                                       {item.url}
                                                    </div>
                                                    </div> 
                                                    <Tooltip id={item.url} data={item.url} />

                                                    <div>
                                                        Created <i> {formatDetectionDatetime(item.createdOn)}</i>
                                                    </div>
                                                </div>
                                                <div onClick={() => this.handleRemoveUrl(item.id, item.customerId)} className={`${Style.cursorStyle} d-flex`} style={{width:'22%'}}>
                                                    <div>
                                                        <img style={{height:'15px'}} src={DashIcon} alt="remove-icon"/>
                                                    </div>
                                                    <div className={`${Style.removeAdmin} ml-1`}>
                                                        Remove URL
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            
                        </div>
                    </div>
                </div>     
                {this.props.statusCode && <ModalPopup statusCode={this.props.statusCode} msg={this.props.userMsg}/> }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userId: state.entities.user.id,
    tenantId: state.entities.user.tenantId,
    needRefreshToken: state.entities.customers.needRefreshToken,
    userMsg: state.entities.customers.userMsg,
    statusCode: state.entities.customers.statusCode,
    refreshToken: state.entities.user.refreshToken,
    loading: state.entities.customers.loading,
    whitelistUrls: state.entities.customers.whitelistUrls,
    selectedCustomer: state.entities.customers.selected,
    getWhitelistUrlsFlag: state.entities.customers.getWhitelistUrlsFlag,
    addWhitelistUrlFlag: state.entities.customers.addWhitelistUrlFlag,
    removeWhitelistUrlFlag: state.entities.customers.removeWhitelistUrlFlag
})

const mapDispatchToProps = (dispatch) => ({
    getWhitelistUrls: (customerId) => dispatch(getWhitelistUrls(customerId)),
    removeWhitelistUrl: (customerId, whitelistUrlId) => dispatch(removeWhitelistUrl(customerId, whitelistUrlId))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WhitelistUrl))