import React, { Component } from 'react'
import Style from './AdminManagement.module.css'
import { connect } from 'react-redux'
import { formatDetectionDatetime } from '../utilities/datetime'
import { withRouter } from 'react-router-dom';
import ModalPopup from '../components/common/ModalPopup'
import LoaderWrapper from './common/LoaderWrapper/LoaderWrapper';
import DashIcon from '../assets/minus.png' 
import { addWhitelistDomain, getWhitelistDomains, removeWhitelistDomain } from '../store/entities/customers'

class WhitelistDomain extends Component {

    componentDidMount() {
        if(this.props.selectedCustomer) {
            this.props.getWhitelistDomains(this.props.selectedCustomer.id)
        }    
    }

    componentDidUpdate(prevProps) {
        if(prevProps.addWhitelistDomainFlag === true && this.props.addWhitelistDomainFlag === false) {
            this.props.getWhitelistDomains(this.props.selectedCustomer.id)
        }
        if(prevProps.removeWhitelistDomainFlag === true && this.props.removeWhitelistDomainFlag === false) {
            this.props.getWhitelistDomains(this.props.selectedCustomer.id)
        }
    }

    handleRemoveDomain = (whitelistDomainId, customerId) => {      
            this.props.removeWhitelistDomain( customerId, whitelistDomainId);
    }

    handleAddDomain = () => {
        this.props.history.push({pathname: `/customers/${this.props.selectedCustomer.id}/settings/add-whitelist-domain`}) 
    }

    render() {
        let { whitelistDomains } = this.props
        return (
            <div>
                <div className={'mt-4 w-100'}>
                    <div className={`${Style.titleStyle}`}>
                        Allowed Domains
                    </div>
                    <div className={`${Style.shortInfoStyle} w-100 d-flex align-items-center mt-3 px-5`}>
                        <div style={{width: '80%'}} className={`${Style.borderStyle} px-4`}>
                            <div className={`${Style.fontBold}`}>
                                Current Custom Domains
                            </div>
                            <div className={`${Style.currentAdminCount}`}>
                            {whitelistDomains.length}
                            </div>
                        </div>
                        <div style={{width: '20%'}} className={'ml-4'}>
                            <div className={`${Style.fontBold}`}>
                            Add Allowed Domain
                            </div>
                            <div className={'d-flex mt-3'}>
                                <button className={`${Style.submitButton} focus-none px-4`} onClick={this.handleAddDomain}>Add Domain</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'mt-5'}>
                    <div className={Style.adminsStyle}>
                        Domains
                    </div>
                    <div className={'mt-3'}>
                        <div className={`d-flex px-5 mx-2`}>
                            <div className={`${Style.fontBold}`} style={{width:'18%'}}><b>Name</b></div>
                            <div className={`${Style.fontBold}`} style={{width:'60%'}}><b>Domain</b></div>
                            <div className={`${Style.fontBold}`} style={{width:'22%'}}><b>Action</b></div>
                        </div>
                        <div className={`${Style.tableContainerStyle} p-4 mt-3`}>
                            <div className={`${Style.tableStyle} ${Style.ScrollStyle}`}>
                                {
                                    // this.props.whitelistDomainFlag 
                                    this.props.whitelistDomainFlag ?
                                        <LoaderWrapper loading={this.props.whitelistDomainFlag} isFlex={true} styles={{top: '50px'}} waitForData={false}></LoaderWrapper>
                                    :
                                    whitelistDomains.map((item, index) => {
                                    return <div key={item.uid} className={`${ index%2 === 0 ? Style.rowBg : Style.customRowBg} px-4 py-2`}>
                                            <div className={`d-flex p-2`}>
                                                <div className={`${Style.fontBold}`} style={{width:'18%'}}>
                                                    {item.displayName}
                                                </div>
                                                <div style={{width:'60%'}}>
                                                    <div>
                                                        {item.authorizedDomain}
                                                    </div>
                                                    <div>
                                                        Created <i> {formatDetectionDatetime(item.created)}</i>
                                                    </div>
                                                </div>
                                                <div onClick={() => this.handleRemoveDomain(item.id, item.customerId)} className={`${Style.cursorStyle} d-flex`} style={{width:'22%'}}>
                                                    <div>
                                                        <img style={{height:'15px'}} src={DashIcon} alt="remove-icon"/>
                                                    </div>
                                                    <div className={`${Style.removeAdmin} ml-1`}>
                                                        Remove Domain
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            
                        </div>
                    </div>
                </div>     
                {this.props.statusCode && <ModalPopup statusCode={this.props.statusCode} msg={this.props.userMsg}/> }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userId: state.entities.user.id,
    tenantId: state.entities.user.tenantId,
    needRefreshToken: state.entities.customers.needRefreshToken,
    userMsg: state.entities.customers.userMsg,
    statusCode: state.entities.customers.statusCode,
    refreshToken: state.entities.user.refreshToken,
    loading: state.entities.customers.loading,
    whitelistDomains: state.entities.customers.whitelistDomains,
    selectedCustomer: state.entities.customers.selected,
    whitelistDomainFlag: state.entities.customers.whitelistDomainFlag,
    addWhitelistDomainFlag: state.entities.customers.addWhitelistDomainFlag,
    removeWhitelistDomainFlag: state.entities.customers.removeWhitelistDomainFlag
})

const mapDispatchToProps = (dispatch) => ({
    getWhitelistDomains: (customerId) => dispatch(getWhitelistDomains(customerId)),
    removeWhitelistDomain: (customerId, whitelistDomainId) => dispatch(removeWhitelistDomain(customerId, whitelistDomainId))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WhitelistDomain))