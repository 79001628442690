import React, { Component } from 'react'
import Style from './AddOrganization.module.css'
import { setError, addWhitelistUrl, getWhitelistUrls } from '../store/entities/customers'
import { connect } from 'react-redux'
import ModalPopup from './common/ModalPopup'

import BackIcon from '../assets/backButton.svg'

const GFORMSTR = 'https://docs.google.com/forms'

class AddWhitelistUrl extends Component {

    constructor() {
        super();
        this.state = {
            url: ''
        };
    }
    componentDidMount() {
        if(this.props.selectedCustomer) {
            this.props.getWhitelistUrls(this.props.selectedCustomer.id)
        }    
    }

    componentDidUpdate(prevProps) {
    }

    handleModalClick = () => {
        this.props.history.push({ pathname: `/customers/${this.props.selectedCustomer.id}/settings`, state: { tab: 'Whitelist Url Management' } })  
    }

    handleChange = (event) => {
        const value = event.target.value
        this.setState({
            url: value.trim()
        })
    }

    handleClick = () => {
            this.props.history.push({ pathname: `/customers/${this.props.selectedCustomer.id}/settings`, state: { tab: 'Whitelist Url Management' } })  
    }

    handleSubmit = () => {
        const { url } = this.state;
        const { whitelistUrls, setError, addWhitelistUrl, selectedCustomer } = this.props;
        let error = '';
    
        try {
            const normalizedUrl = this.ensureHttpScheme(url.trim());
    
            // Basic validation
            if (!url.trim()) {
                error = "URL is required.";
            } 
            else if (url.length > 2048) {
                error = "URL should not exceed 2048 characters.";
            }
            else if (!this.isGoogleFormUrl(normalizedUrl)) {
                error = `Please Add a Google Form URL. (Ex: ${GFORMSTR}...)`;
            } 
            else if (!this.isValidUrl(normalizedUrl)) {
                error = "Please enter a valid URL.";
            }
    
            if (!error) {
                const parsedUrl = new URL(normalizedUrl);
                const trimmedUrl = this.removeHttpProtocol(normalizedUrl);
    
                // Advanced validation
                if (this.isUrlSafe(normalizedUrl)) {
                    error = "URL contains unsafe characters. Please enter a valid URL.";
                } else if (this.isPublicUrl(parsedUrl)) {
                    error = "URL contains private or restricted domains.";
                } else if (this.isUrlDuplicate(trimmedUrl, whitelistUrls)) {
                    error = "URL already exists in the whitelist.";
                } else if (!this.validatePort(parsedUrl)) {
                    error = "Invalid port number. Ports must be 1-65535 and not include reserved ports like 0, 21, 22, 80, 443, 1023.";
                }
            }
    
            // Handle valid submission
            if (!error) {
                const body = { url: encodeURIComponent(this.removeHttpProtocol(normalizedUrl)) };
                addWhitelistUrl(selectedCustomer.id, body);
            } else {
                setError(error);
            }
        } catch (e) {
            setError("An error occurred while adding the domain.");
        }
    };
    
    ensureHttpScheme = (url) => {
        if (!/^https?:\/\//.test(url)) {
            return `https://${url}`;
        }
        return url;
    };
    
    isUrlDuplicate = (url, whitelistUrls) => {
        return whitelistUrls.some(whitelistUrl => whitelistUrl.url.trim() === url.trim());
    };
    
    validatePort = (parsedUrl) => {
        const port = parsedUrl.port ? parseInt(parsedUrl.port, 10) : null;
        const reservedPorts = [0, 21, 22, 80, 443, 1023];
        return port === null || (port > 0 && port <= 65535 && !reservedPorts.includes(port));
    };
    
    isPublicUrl = (parsedUrl) => {
        const privateIpRegex = /^(localhost|127\.0\.0\.1|10\.\d+\.\d+\.\d+|172\.(1[6-9]|2[0-9]|3[0-1])\.\d+\.\d+|192\.168\.\d+\.\d+)$/;
        return privateIpRegex.test(parsedUrl.hostname);
    };
    
    isValidUrl = (url) => {
        const pattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/;
        try {
            return pattern.test(url) && ['http:', 'https:'].includes(new URL(url).protocol);
        } catch {
            return false;
        }
    };
    
    removeHttpProtocol = (url) => {
        return url.replace(/^https?:\/\//, '');
    };
    
    isUrlSafe = (url) => {
        const unsafeUrlRegex = /(<script>|javascript:|on\w+=|('|%27|%22|--|#|;|&&|\|\|)|(%00|%2F%2F|%5C))/;
        return unsafeUrlRegex.test(url);
    };

    isGoogleFormUrl = (url) => {
        return url.startsWith(GFORMSTR, 0);
    }
    
    render() {
        return (
            <div className={'px-5 mt-4'}>
                <div className={`${Style.headerBg} px-5 d-flex align-items-center justify-content-between`}>
                    <div onClick={this.handleClick} style={{ cursor: 'pointer' }}>
                        <img style={{ height: '1.5vw' }} src={BackIcon} alt={'back-icon'} />
                        Return to Allowed Urls
                    </div>
                </div>
                <div className={`${Style.detailsContainer} w-50 mt-4 py-5 px-5`}>
                    <div className={`d-flex flex-column`}>
                        <input name='url' placeholder={'https://docs.google.com/forms...'} className={`${Style.inputStyle} focus-none mt-3`} onChange={this.handleChange} />
                    </div>
                    <div className={'mt-4'}>
                        <button onClick={this.handleSubmit} className={`${Style.submitButton} px-4 focus-none`}>Add URL</button>
                        <button onClick={this.handleClick} className={`${Style.cancelButton} px-4 ml-2 focus-none`}>Cancel</button>
                    </div>
                </div>
                {this.props.statusCode && <ModalPopup handleClick={this.handleModalClick} statusCode={this.props.statusCode} msg={this.props.userMsg}/> }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    selectedCustomer: state.entities.customers.selected,
    userRole: state.entities.user.roles,
    userMsg: state.entities.customers.userMsg,
    statusCode: state.entities.customers.statusCode,
    whitelistUrls: state.entities.customers.whitelistUrls,

})

const mapDispatchToProps = (dispatch) => ({
    addWhitelistUrl: (customerId, body) => dispatch(addWhitelistUrl(customerId, body)),
    setError: (error) => dispatch(setError(error)),
    getWhitelistUrls: (customerId) => dispatch(getWhitelistUrls(customerId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddWhitelistUrl)